<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-button type="primary" style="margin-left:0; float:right" @click="updateReport" v-if="permission_edit">保存</a-button>
    <div style="clear:both"></div>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="24" :md="24" :sm="24">
        <a-form-item
          label="报告编号">
          <a-input
            v-model="formData.reportNo"
            placeholder="请输入报告编号" />
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item label="供水工程">
          <a-input
            v-model="formData.projectName"
            disabled />
        </a-form-item>
        <a-form-item label="分散供水设施" hidden>
          <a-input
            v-model="projectName"
            disabled />
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item label="检测单位">
          <a-input
            v-model="formData.realCenterName"
            placeholder="请输入检测单位" />
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-button style="margin-top:43px;">单位管理</a-button>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item
          label="供水季节">
          <a-select v-model="formData.flagSeason" placeholder="请选择" default-value="0">
            <a-select-option
              :value="item.value"
              :selected="item.value == formData.flagSeason"
              v-for="item in seasonTypeMenuList"
              :key="item.id"
            >{{ item.description }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item
          label="采样人员">
          <a-input
            v-model="formData.sampleName"
            placeholder="请输入采样人员" />
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item
          label="采样日期">
          <a-date-picker
            style="width: 100%"
            @change="sampleDateChange"
            placeholder="请选择日期"
            v-decorator="['sampleDate',{initialValue:formData.sampleDate}]"/>
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item
                label="编制人员">
          <a-input
                  v-model="formData.detectionName"
                  placeholder="请输入编制人员" />
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item
          label="编制日期">
          <a-date-picker
            style="width: 100%"
            @change="reportDateChange"
            placeholder="请选择日期"
            v-decorator="['reportDate',{initialValue:formData.reportDate}]"/>
        </a-form-item>
      </a-col>
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item
                label="检测日期">
          <a-range-picker
                  :placeholder="['开始时间', '结束时间']"
                  format="YYYY-MM-DD"

                  v-decorator="['detectionDate',{initialValue}]"
                  @change="onDateChange" />

<!--          <a-date-picker-->
<!--                  style="width: 100%"-->
<!--                  @change="detectionDateChange"-->
<!--                  placeholder="请选择日期"-->
<!--                  v-decorator="['detectionDate',{initialValue:formData.detectionDate}]"/>-->
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item
          label="审核人员">
          <a-input
            v-model="formData.verifyName"
            placeholder="请输入审核人员" />
        </a-form-item>
      </a-col>
      <a-col :lg="6" :md="6" :sm="24">
        <a-form-item
          label="批准人员">
          <a-input
            v-model="formData.acceptName"
            placeholder="请输入批准人员" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit" >Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { getAreaList, getMenuList } from '@/api/basic/common'
import { getReportInfo, updateReportInfo } from '@/api/quality/project'

export default {
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    },
    reportId: {
      type: String
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      // 接口参数
      projectName: '工程名称',
      projectId: '1',
      submitName: '管理员', // 录入人员姓名
      submitId: '1', // 录入人员ID
      formData: {},
      initialValue:['2020-01-01','2021-01-03'],
      // 返回数据
      waterTypeMenuList: [],
      fenTypeMenuList: [],
      ScaleTypeMenuList: [],
      seasonTypeMenuList: [],
      departmentList: [],
      // 页面参数
      isSave: true,
      // 接口数据
      reportInfo: {}
    }
  },
  created () {
    this.permission_edit = localStorage.getItem("permission_edit") == 'true'

    // 请求接口
    this.getWaterTypeMenu()
    this.getFenTypeMenu()
    this.getScaleTypeMenu()
    this.getSeasonTypeMenu()
    this.getReport()
  },
  methods: {
    // 接口请求
    getReport () {
      getReportInfo({ id: this.reportId }).then(response => {
        this.formData = response.data

        if(this.formData.detectionDate != null)
        {
          this.initialValue = this.formData.detectionDate.split('~')
        }

        delete this.formData.reportSampleEntities
        this.isSave = true
      })
    },
    updateReport () {
      updateReportInfo(this.formData).then(response => {
        if (response.code === 0) {
          this.$message.info(`数据更新成功`)
        } else {
          this.$message.error(`数据更新失败,错误原因:${response.msg}`)
        }
      })
    },
    // 获取菜单
    getSeasonTypeMenu () {
      getMenuList({ menu: '供水季节' }).then(response => {
        this.seasonTypeMenuList = response.data.list
        this.formData.flagSeason = response.data.list[0].value
      })
    },
    getWaterTypeMenu () {
      getMenuList({ menu: '水源类型' }).then(response => {
        this.waterTypeMenuList = response.data.list
        this.formData.waterTypeId = response.data.list[0].value
      })
    },
    getFenTypeMenu () {
      getMenuList({ menu: '分散设施' }).then(response => {
        this.fenTypeMenuList = response.data.list
        // this.fenType = response.data.list[0].value
      })
    },
    getScaleTypeMenu () {
      getMenuList({ menu: '集中设施' }).then(response => {
        this.departmentList = response.data.list
        // this.listParam.wasuScales = response.data.list[0].value
      })
    },

    onDateChange(date, dateString) {
      console.log("onDateChange")
      console.log(date)
      console.log(dateString)
      this.formData.detectionDate = dateString[0]+"~"+dateString[1]
      console.log(this.formData.detectionDate)

    },
    // 日期选择
    sampleDateChange (value) {
      // console.log(value)
      this.formData.sampleDate = value.format('YYYY-MM-DD')
    },
    detectionDateChange (value) {
      // console.log(value)
      this.formData.detectionDate = value.format('YYYY-MM-DD')
    },
    reportDateChange (value) {
      // console.log(value)
      this.formData.reportDate = value.format('YYYY-MM-DD')
    },
    // 操作
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$notification['error']({
            message: 'Received values of form:',
            description: values
          })
        }
      })
    },
    validate (rule, value, callback) {
      const regex = /^user-(.*)$/
      if (!regex.test(value)) {
        callback(new Error('需要以 user- 开头'))
      }
      callback()
    }
  }
}
</script>

<style scoped>

</style>
