<template>
  <a-card :bordered="false">
    <a-card class="card" title="检测报告基本信息" :bordered="false">
      <div slot="extra">
        <a-button @click="backReport" style="margin-right:15px;"><a-icon type="unordered-list" /> 返回</a-button>
        <a-button @click="reportView()">报告预览</a-button>
      </div>
      <repository-form ref="repository" :showSubmit="false" :reportId="$route.query.reportId" />
    </a-card>
    <a-card class="card" title="检测报告水样信息" :bordered="false" v-if="!viewSelected">
      <div slot="extra">
        <a-button type="primary" @click="sampleAdd()" v-if="permission_edit">增加水样</a-button>
      </div>
      <a-table
        :columns="columns"
        :dataSource="reportSample"
        :pagination="false"
      >
        <template slot="serial" slot-scope="text, record, index">
          <template>{{ index + 1 }}</template>
        </template>
        <template v-for="(col, i) in ['waterCode', 'type', 'addr']" :slot="col" slot-scope="text, record">
          <a-input
            :key="col"
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            :placeholder="columns[i].title"
          />
          <template v-else>{{ text }}</template>
        </template>
        <template slot="action" slot-scope="text, record">
          <template v-if="permission_edit">
            <a @click="sampleEdit(record.id)">编辑</a>
            <a-divider type="vertical" />
            <a @click="sampleDelete(record.id)">删除</a>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-card>
</template>

<script>
import { getReportInfo, addSample, deleteSample } from '@/api/quality/project'
import RepositoryForm from './reportEdit/RepositoryForm'

export default {
  name: 'AdvancedForm',
  components: {
    RepositoryForm
  },
  data () {
    return {
      loading: false,
      memberLoading: false,
      viewSelected: false,
      // table
      columns: [
        {
          title: '#',
          dataIndex: 'serial',
          key: 'serial',
          width: '5%',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '水样编号',
          dataIndex: 'waterCode',
          key: 'waterCode',
          width: '30%',
          scopedSlots: { customRender: 'waterCode' }
        },
        {
          title: '水样类型',
          dataIndex: 'sampleTypeName',
          key: 'sampleTypeName',
          width: '25%',
          scopedSlots: { customRender: 'sampleTypeName' }
        },
        {
          title: '采样地点',
          dataIndex: 'addr',
          key: 'addr',
          width: '25%',
          scopedSlots: { customRender: 'addr' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: '15%',
          scopedSlots: { customRender: 'action' }
        }
      ],
      errors: [],
      // 接口参数
      sampleParam: {
        addr: '',
        centerId: 0,
        // id: 1,
        isDelete: 0,
        reportDataEntities: null,
        reportId: this.$route.query.reportId,
        status: 0,
        waterCode: '',
        sampleType: 0
      },
      // 返回数据
      reportSample: []
    }
  },
  created () {
    this.permission_edit = localStorage.getItem("permission_edit") == 'true'

    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.sampleParam.centerId = area.depId

    this.getReport()
  },
  methods: {
    // 接口请求
    getReport () {
      getReportInfo({ id: this.$route.query.reportId }).then(response => {
        this.reportSample = response.data.reportSampleEntities
      })
    },
    sampleAdd () {
      addSample(this.sampleParam).then(response => {
        if (response.code === 0){
          var id = response.data
          this.$router.push({
            path: '/test/report/sample',
            query: {
              id: id
            }
          })
        } else {
          this.$message.error('添加失败')
        }
      })
    },
    sampleEdit (id) {
      this.$router.push({
        path: '/test/report/sample',
        query: {
          id: id
        }
      })
    },
    sampleDelete (id) {
      var self = this
      this.$confirm({
        title: '确认删除',
        content: `是否确认删除当前水样？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            deleteSample({ id: id }).then(response => {
              self.$message.error('删除成功')
              self.getReport()
            }).catch((e) => {
              self.$message.error('删除成功')
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    },
    // 操作方法
    viewS () {
      this.viewSelected = !this.viewSelected
    },
    handleSubmit (e) {
      e.preventDefault()
    },
    reportView () {
      this.$router.push({
        path: '/test/report/view',
        query: {
          reportId: this.$route.query.reportId
        }
      })
    },
    backReport () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
  .card{
    margin-bottom: 24px;
  }
</style>
